<template>
    <div class="payments">
        <div class="payments__wrapper">
            <div class="payments__head">
                <button class="payments__back" @click="$router.back()"><i class="fas fa-arrow-left"></i></button>
                <h1 class="payments__title">
                    <span class="ml-1">Платежи</span>
                </h1>
            </div>
            <h3 class="payments__company">{{ user.uf_name }} | {{ user.uf_contract }}</h3>

            <div class="flex w-full">
              <div class="payments__updated" v-if="payments.length">
                <p class="ml-1" >Баланс обновлен {{ balanceUpdate }}</p>
              </div>
              <div class="balance" v-if="balance" >
                <p  class="ml-1">Остаток: {{balance}}</p>
              </div>
            </div>


            <div v-if="payments.length" class="payments__table-wrapper">
                <table class="payments__table" v-if="payments.length">
                    <thead class="payments__table-headings">
                    <th class="payments__table-heading">№
                    </th>
                    <th class="payments__table-heading" v-for="header in headers" :key="header.value">{{ header.name }}</th>
                    </thead>
                    <tbody class="payments__table-body">
                    <tr class="payments__table-row"
                        v-for="(item, idx) in payments" :key="idx">
                        <td class="p-3">{{ idx + 1 }}</td>
                        <td class="p-3" :class="{'text-green-400': header.value === 'status' && item[header.value]==='Завершена',
                             'text-yellow-400':header.value === 'status' && item[header.value]==='Новая'}"
                            :key="header.value" v-for="header in headers">{{ item[header.value] }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <h3 class="payments__empty">
                    Список платежей пуст
                    <g-emoji>😥</g-emoji>
                </h3>
            </div>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Payments",
    data: () => ({
        headers: [
            {name: 'Номер платежа', value: 'paymentNumber'},
            {name: 'Сумма', value: 'sum'},
            {name: 'Дата оплаты', value: 'paymentDate'},

        ],
        selectedByAdmin: false,
        loading: false,
        user: {},
        balance:""
    }),
    computed: {
        ...mapGetters({
            payments: 'clientPayments/payments'
        }),
        balanceUpdate() {
            return this.payments[0].balanceUpdate
        }
    },
    created() {
        this.$store.dispatch('admin/getContractSingle', {id:this.$route.params.id}).then(res => {
            this.user = res.data.data
            return this.$store.dispatch('admin/getBalanceByUnp', this.user.uf_unp)
        }).then(res => {
            const {balance} = res.data.data || {}
            this.balance = balance
        }).catch(err => {
            this.$notify({
                title: 'Ошибка',
                text: err.response.data.message,
                type: 'error'
            })
        })
        this.$store.commit('clientPayments/setSelectedUser', this.$route.params.id)
        this.$store.dispatch('clientPayments/getPaymentsAsAdmin')
    },
    beforeDestroy() {
        this.$store.commit('clientPayments/destroy')
    },



}
</script>

<style scoped>
.payments{
    @apply w-full mx-auto my-10
    xl:container
}
.payments__wrapper{
    @apply
    sm:my-10
    xl:mx-10
}
.payments__head{
    @apply flex
}
.payments__back{
    @apply text-blue-500
}
.payments__title{
    @apply dark:text-white text-gray-800 text-2xl mb-1 w-full flex justify-between
}
.payments__company{
    @apply mx-1 my-3
}
.payments__updated{
    @apply sm:w-full lg:w-3/4 dark:bg-blue-900 p-3 my-5
}
.payments__table-wrapper{
    @apply  overflow-y-scroll max-h-128 shadow-md
}
.dark .payments__table-wrapper{
    box-shadow: none;
}
.payments__table{
    @apply text-left w-full border-collapse  dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}
.payments__table-headings{
    @apply dark:bg-gray-800 bg-white text-gray-500
}
.payments__table-heading{
    @apply p-3 select-none
}
.payments__table-body{
    @apply  dark:text-gray-400 text-gray-600
}
.payments__table-row{
    @apply dark:bg-gray-800 bg-white  dark:hover:bg-gray-700 hover:bg-gray-300
}
.payments__empty{
    @apply text-2xl text-center w-full p-10
}
.balance{
  @apply sm:w-full lg:w-1/4 dark:bg-green-900 p-3 my-5
}
</style>